@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mattone";
  src: url("../src/assets/fonts/Mattone-Black.otf") format("truetype"),
    url("../src/assets/fonts/Mattone-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Mattone";
  src: url("../src/assets/fonts/Mattone-Bold.otf") format("truetype"),
    url("../src/assets/fonts/Mattone-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mattone";
  src: url("../src/assets/fonts/Mattone-Regular.otf") format("truetype"),
    url("../src/assets/fonts/Mattone-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body {
  margin: 0;
  font-family: "Mattone", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: white;
}

.hero-wrap {
  padding: 2rem 10rem;
  max-width: 1400px;
  margin: auto;
}
.hero {
  min-height: 70vh;
  height: 100vh;
  width: 100%;
  padding: 2rem 8rem;
  background-image: url("../src/assets/images/mainBanner.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.headerLink {
  font-size: 18px;
  font-weight: bold;
}

h1,
.shadowed {
  text-shadow: 10px 8px 7px rgba(12, 12, 12, 0.547);
}

.yellow-gradient {
  background: linear-gradient(to bottom, #ffd500, #f99d00);
}

.trailer-placeholder {
  padding: 2rem 8rem;
  background-image: url("../src/assets/images/trailer.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}
.trailer-card {
  background: linear-gradient(to right, #0a0a0a, #303030, #0a0a0a);
}
.gradient-text {
  background: linear-gradient(to bottom, #01a6e4, #0e60a7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.gradient-text-yellow {
  background: linear-gradient(to bottom, #ffd500, #f99d00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.video-responsive {
  position: relative;
  overflow: hidden;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.gradient-divider-left {
  width: 20vw;
  height: 2px;
  background: linear-gradient(to right, #181818, #ffffff);
}
.gradient-divider-right {
  width: 20vw;
  height: 2px;
  background: linear-gradient(to right, #ffffff, #181818);
}

.gradient-divider {
  height: 2px;
  background: linear-gradient(to right, #181818, #ffffff, #181818);
}

.blur-backdrop {
  backdrop-filter: blur(10px);
  background-color: rgba(13, 13, 13, 0.3);
}

.gradient-border {
  border: 1px solid transparent;
  background-image: linear-gradient(to bottom, #202020, #cac5c5);
  background-origin: border-box;
  background-clip: border-box;
  border-radius: 2px;
}

.footer {
  padding: 2rem;
  background-image: url("../src/assets/images/footerBanner.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.carousel-images {
  position: relative;
  border-radius: 10px;
  height: 400px;
  max-width: 650px;
  margin: auto;
  overflow: hidden;
}
.carousel-images img {
  width: 99%;
  height: 99%;
  border-radius: 8px;
  border: #ff00008e solid 2px;
}
.slide_direction {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
/* }
.left,
.right {
  background-color: #fb666675;
  color: #fff;
  padding: 10px 8px 8px 13px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
}
.left {
  left: 0;
}
.right {
  right: 0;
} */
.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.active {
  background-color: #fa2020;
}
@media (max-width: 970px) {
  .hero {
    padding: 2rem 4rem;
  }
  .hero-wrap {
    padding: 2rem 4rem;
  }
}
@media (max-width: 768px) {
  .hero {
    padding: 2rem 3rem;
  }
  .hero-wrap {
    padding: 2rem 3rem;
  }
}
@media (max-width: 480px) {
  .hero {
    padding: 2rem;
  }
  .hero-wrap {
    padding: 2rem;
  }
}
